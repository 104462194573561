import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, switchMap, take } from 'rxjs/operators';
import { AlertService } from './alerts/alert.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth, private router: Router,private alertService:AlertService) { }

  canActivate(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      take(1),
      switchMap(async user => {
        if (user) {
          const token = await user.getIdTokenResult();
          if (token.claims.isAdmin) {
            return token.claims.isAdmin;
          } else {
              this.alertService.accessDeniedSwal().then(() => {
              this.router.navigate(['auth']);
            });
          }
        } else {
          this.router.navigate(['auth']);
        }
      }),
      first(),
    );
  }
}
