import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private ADMIN_PATH = 'rivaln_app/private_db/admin';
  authState: any = null;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.afAuth.authState.subscribe(user => {
      this.authState = user;
    });
  }

  /**
   * ====== Utility function =======
   * authenticated()
   * currentUser()
   * currentUserObservable()
   * currentUserId()
   **/

  get authenticated(): boolean {
    return this.authState !== null;
  }

  get currentUser(): any {
    return this.authenticated ? this.authState : null;
  }

  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : '';
  }

  // tslint:disable-next-line: typedef
  async getAdminStatus() {
    const token = await this.authState.getIdTokenResult();
    return token.claims.isAdmin;
  }

  /**
   * @emailSignUp()
   * @emailLogin()
   * @resetPassword()
   * @signOut()
   * */

  async emailSignUp(credentials: any): Promise<[void, void]> {
    const result = await this.afAuth.createUserWithEmailAndPassword(
      credentials.email,
      credentials.password,
    );
    const nameP = this.updateUser(credentials, result);
    const userP = this.saveUserInfo(credentials);
    return Promise.all([nameP, userP]);
  }

  async emailLogin(email: string, password: string): Promise<firebase.auth.UserCredential> {
    const res = await this.afAuth.signInWithEmailAndPassword(
      email,
      password,
    );
    this.authState = res.user;
    return res;
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  signOut(): void {
    this.afAuth.signOut();
    this.router.navigate(['auth']).then(() => { });
  }

  updateUser(userDetails, result): Promise<void> {
    return result.user.updateProfile({
      displayName: userDetails.userName,
    });
  }

  saveUserInfo(userDetails): void {
    const { userName, email } = userDetails;
    const adminId = this.currentUserId;
    this.afs
      .collection(this.ADMIN_PATH)
      .doc(adminId)
      .set({ userName, adminId, email, isExist: true }, { merge: true });
  }
}

export class EmailValidator {
  static isValid(control: FormControl): any {
    // tslint:disable-next-line: max-line-length
    const reg = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = reg.test(control.value);
    if (valid) {
      return null;
    }
    return { invalidEmail: true };
  }
}

export class PasswordValidator {
  static isValid(control: FormControl): any {
    // tslint:disable-next-line: max-line-length
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
    const valid = reg.test(control.value);
    if (valid) {
      return null;
    }
    return { invalidPass: true };
  }
}
