import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  openAddPlayersToTournamentSwal(playersNames, tournamentName, tournamentCost) {
    return Swal.fire({
      title: `You want to add ${playersNames} to ${tournamentName}?`,
      text: `${tournamentCost} coins will be deducted from 
    players wallet.`,
      type: 'info',
      customClass: 'swal_width',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add!',
    });
  }
  openRemoveSelectedPlayersFromTournamentSwal(
    userNames: string,
    tournamentName: string,
    tournamentCost
  ) {
    return Swal.fire({
      title: `You want to remove ${userNames} from ${tournamentName}?`,
      text: `${tournamentCost} coins will be returned to players `,
      type: 'warning',
      showCancelButton: true,
      customClass: 'swal_width',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove!',
    });
  }
  openRemovePlayerFromTournament(userName, tournamentName, tournamentCost) {
    return Swal.fire({
      title: `${userName} will be removed from ${tournamentName}.`,
      text: ` ${tournamentCost} coins will be returned to ${userName} `,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove user!',
    });
  }
  openAddTeamsToTournamentSwal(teamNames:string, tournamentName:string, tournamentCost) {
    return Swal.fire({
      title: `You want to add ${teamNames} to ${tournamentName}?`,
      text: `${tournamentCost} coins will be deducted from 
    players wallet.`,
      type: 'info',
      customClass: 'swal_width',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add!',
    });
  }

  openRemoveTeamsFromTournamentSwal(teamNames,tournamentName, tournamentCost, ) {
    return Swal.fire({
      title: `You want to remove ${teamNames} from ${tournamentName}?`,
      text: `${tournamentCost} coins will be returned to 
  players wallet.`,
      type: 'info',
      customClass: 'swal_width',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove!',
    });
  }
  openRemoveSelectedTeamFromTournamentSwal(teamName,tournamentName,tournamentCost) {
    return Swal.fire({
      title: `You want to remove ${teamName} from ${tournamentName}?`,
      text: `${tournamentCost} coins will be returned to the 
      team wallet.`,
      type: 'info',
      customClass: 'swal_width',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove!',
    })
  }

  openRemoveUserOfTeamFromTournamentSwal(userNames,teamName,tournamentName){
    return Swal.fire({
      title: `You want to remove ${userNames} from ${teamName} of ${tournamentName}?`,
      text: 'Do You want to remove this user?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove user!',
    })

  }
  openDeleteProductofStore(productName,storeName){
    return Swal.fire({
      title: `You want to remove ${productName} from ${storeName} ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove product!',
    })
  }
  openFileWrongFormatSwal(fileName){
    return Swal.fire({
      title: `Wrong File Format. Please Upload only ${fileName}`,
      type: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    })
  }
  openExcelWrongInterfaceSwal(){
    return Swal.fire({
      title: `Wrong File Format. Please check your file`,
      type: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    })
  }
  openStoreUniqueNameSwal(storeName){
    return Swal.fire({
      title: `The Store with name '${storeName}' is already exist. Please choose another name`,
      type: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    })
  }
  openCheckExcelDataWarning(message){
    return Swal.fire({
      title: `${message}`,
      type: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    })
  }
  openWarningMessage(message){
    return Swal.fire({
      title: `${message}`,
      type: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    })
  }
openAdminExistSwalWarning(email) {
  return Swal.fire({
    title: `Admin with ${email} email is already exists. Please choose another email`,
    type: 'warning',
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'OK',
  })
}
removeStore(storeName){
 return Swal.fire({
    title: 'Are you sure?',
    text: `You want to delete  "${storeName}" `,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  })
}
changeBlockedUsersStatus(isBlocked: boolean){
  return Swal.fire({
    title: 'Are you sure?',
    text: `Do you want to ${!isBlocked ? 'unblock' : 'block'} the user!`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: !isBlocked ? 'Yes, unblock!' : 'Yes, block!',
  })
}
changeBlockedTeamsStatus(isBlocked: boolean){
  return Swal.fire({
    title: 'Are you sure?',
    text: `Do you want to ${!isBlocked ? 'unblock' : 'block'} the team!`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: !isBlocked ? 'Yes, unblock!' : 'Yes, block!',
  })

}
accessDeniedSwal(){
  return  Swal.fire({
    type: 'warning',
    title: 'Opps! Access denied',
    text: `Please contact to adminitration!`,
  })
}

}
