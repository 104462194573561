import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const APP_ROUTES: Routes = [{
  path: '',
  children: [
    {
      path: 'base',
      loadChildren: () => import('./base/base.module').then(m => m.BaseModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'auth',
      loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {
      path: '**',
      redirectTo: 'base',
      pathMatch: 'full'
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
