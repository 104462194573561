// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBSeEgyNEPuHtn7M4ddBmO5W7L6C3AYcaA',
    authDomain: 'rivaln-e1d2a.firebaseapp.com',
    databaseURL: 'https://rivaln-e1d2a.firebaseio.com',
    projectId: 'rivaln-e1d2a',
    storageBucket: 'rivaln-e1d2a.appspot.com',
    messagingSenderId: '893938853647',
    appId: '1:893938853647:web:a91fc56ce30eaf2545007a',
    measurementId: 'G-54TVWE54WM'
  },

  pushNotificationToken:'AAAA0CLoZw8:APA91bHdci62DcSHh3oKbxWffC0geEgN9J4PP4BLopP-rtLj1GxIavjSzxI3SsRw-107UlroU5XADRBUDJUfaHIg_1oGGpQ2Xc3dX-R10LQ79QmYjWqb0Tzdffr41Y8JmSocv6gWKobL'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
