import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spinner$:BehaviorSubject<Boolean> = new BehaviorSubject(false);
  constructor() { }

  showSpinner(){
    this.spinner$.next(true);
  }
  hideSpinner(){
    this.spinner$.next(false)
  }
  isSpinnerShow(){
    return this.spinner$;
  }
}
