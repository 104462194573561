import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {SpinnerService} from '../app/services/spinner/spinner.service';

@Component({
  selector: 'rvln-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'rivaln-admin';
  isLoading:Boolean = false;

  constructor(
    public translateService: TranslateService,
    private spinnerService:SpinnerService
  ) { }

  ngOnInit() {
      this.spinnerService.isSpinnerShow().asObservable().pipe().subscribe(res=>{
      this.isLoading = res;
    })
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

}
